import './ItemCard.css';
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { CartItemsContext } from "../../../Context/CartItemsContext";
import { IconButton } from '@mui/material';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { WishItemsContext } from '../../../Context/WishItemsContext';
import { LazyLoadImage } from "react-lazy-load-image-component";
import Item from '../../Item/Item';
import { Circle } from '@mui/icons-material';
import LI from './LI';

const ItemCard = (props) => {
    const [isHovered, setIsHovered] = useState(false)
    const  cartItemsContext  = useContext(CartItemsContext)
    const wishItemsContext = useContext(WishItemsContext)

    const handleAddToWishList = () => {
        wishItemsContext.addItem(props.item)
    }

    const handleAddToCart = () => {
        cartItemsContext.addItem(props.item, 1)
    }

    return ( 
        <div className="product__card__card">
             {/* col-sm-6 col-md-4 col-lg-4 */}
        
                <div className="product__card">
                <div className='row col-12'>
                <div className='col-3'>
                    <div className="product__image" 
                    // onMouseEnter={() => setIsHovered(true)}
                    // onMouseLeave={() => setIsHovered(false)}
                > 
                    {/* {isHovered? <img src={`https://shema-backend.vercel.app/public/${props.item.category}/${props.item.image[1].filename}`} alt="item" className="product__img"/>: <img src= {`https://shema-backend.vercel.app/public/${props.item.category}/${props.item.image[0].filename}`} alt="item" className="product__img"/> } */}
                   
                    {/* {<img src={`https://shema-backend.vercel.app/public/${props.item.category}/${props.item.image[1].filename}`} alt="item" className="product__img"/>} */}
                    {/* {<img src={props.item.productImage} alt="item" className="product__img"/>} */}
                    {/* {<img src={props.item.image[0].path} alt="item" className="product__img"/>} */}
                    {<img src={props.item.p_Image} alt="item" className="product__img"/>} 

                </div>
                </div>
                <div className='col-9'>
                    <div className='acolor'>
                        <span style={{color:'black'}}>
                    <Link to={`/item/${props.item.producT_ID}`} param={props}>
                           {props.item.producT_NAME}
                        </Link></span>
                    </div>
                    <div style={{display:'flex'}}>
                    <div style={{width:'60%'}}><ul>
                    {props.item.product_Highlights.map((data) => <LI highlights={data.highlights}/>)}
                       </ul></div>
                    <div style={{width:'40%'}}>csc</div>
                    </div>
                </div>
                </div>
            </div>
           
        </div>
     );
}
 
export default ItemCard;