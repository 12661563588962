import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import axios from 'axios'
import ReactLoading from 'react-loading';
import Category from '../Components/Category/Category';

const CategoryView = () => {
    const param = useParams()
    const [ menItems, setMenItems ] = useState()
    const [ womenItems, setWomenItems ] = useState()
    const [ kidsItems, setKidsItems ] = useState()
    const [ loading , setLoading ] = useState(true) 

    const [ SmartWatch, setSmartWatch ] = useState()
    const [ Earbuds, setEarbuds ] = useState()
    const [ Neckband, setNeckband ] = useState()
    const [ Headphone, setHeadphone ] = useState()


    useEffect(() => {
        axios.get("https://shema-backend.vercel.app/api/items")
            .then(res => {
                setMenItems(res.data.filter((item) => item.category === "men"))
                setKidsItems(res.data.filter((item) => item.category === "kids" ))
                setWomenItems(res.data.filter((item) => item.category === "women")) 
                setLoading(false)
            })
            .catch(err => console.log(err))

        window.scrollTo(0, 0)
    }, [param.id])
    
    useEffect(() => {
           //axios.get("https://shema-backend.vercel.app/api/items")
          axios.get("https://clientvoodlife.ursindia.com/api/Product/Product_list")
                
              .then(res => {
                setSmartWatch(res.data.filter((item) => item.category_Name === "Smart Watch"))
                 setEarbuds(res.data.filter((item) => item.category_Name === "Earbuds" ))
                 setNeckband(res.data.filter((item) => item.category_Name === "Neck Band"))
                 setHeadphone(res.data.filter((item) => item.category_Name === "Head Phone"))
                })
              .catch(err => console.log(err))
  
          window.scrollTo(0, 0)
  }, [param.id]);

    return ( 
        <div className='d-flex min-vh-100 w-100 justify-content-center align-items-center m-auto'>
            {loading && <ReactLoading type="balls" color='#FFE26E' height={100} width={100} className='m-auto'/>}
            {/* { menItems && param.id === 'men' && <Category name="Men's Fashion" items={menItems} category="men"/>} */}
            { kidsItems && param.id === 'kids' && <Category name="Kids Fashion" items={kidsItems} category="kids"/>}
            {/* { womenItems && param.id === 'women' && <Category name="Women's Fashion" items={womenItems} category="women"/>} */}


            { SmartWatch && (param.id === 'Smart-Watch') && <Category name="Smart-Watch" items={SmartWatch} category="Smart-Watch"/>}
            { Earbuds && param.id === 'Earbuds' && <Category name="Earbuds" items={Earbuds} category="Earbuds"/>}
            { Neckband && param.id === 'Neck-Band' && <Category name="Neck Band" items={Neckband} category="Neck-Band"/>}
            { Headphone && param.id === 'Head-Phone' && <Category name="Head Phone" items={Headphone} category="Head-Phone"/>}

        </div>
     );
}
 
export default CategoryView;