
import React, { useState } from "react";
import { Link } from 'react-router-dom';
import './LoginCard.css';
import axios from "axios";


const LoginCard = () => {
    const [email_id, setEmail_id] = useState("");
    const [u_Password, setU_Password] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();

    // Send a POST request to the login API
    axios.post("https://clientvoodlife.ursindia.com/api/User/Login",{email_id,u_Password})
      .then((response) => {
        if(response.data=="1")
        {
        // If the login is successful, store the session token in the local storage
        localStorage.setItem("sessionToken", response.data.sessionToken);
        // alert("h2");
        // Redirect the user to the home page
        window.location.href = "/";
        }
        else
        {
            alert('User Not Found');
        }
      })
      .catch((error) => {
        // Handle the error
        console.log(error);
      });
  };

    return ( 
        <div className="login__card__container">
            <div className="login__card">
                <div className="login__header">
                    <h1>Login</h1>
                </div>
                <div className="login__inputs">
                <form onSubmit={handleSubmit}>
                    <div className="email__input__container input__container">
                        <label className="email__label input__label">Email</label>
                        <input type="email" className="email__input login__input" placeholder='example@gmail.com'  value={email_id} onChange={(e) => setEmail_id(e.target.value)}/>
                    </div>
                    <div className="password__input__container input__container">
                        <label className="password__label input__label" >Password</label>
                        <input type="password" className="password__input login__input" placeholder='**********' value={u_Password} onChange={(e) => setU_Password(e.target.value)}/>
                    </div>
                    <div className="login__button__container">
                        <button className="login__button" type="submit">LOGIN</button>
                    </div>
                </form>
                </div>
                <div className="login__other__actions">
                    <div className="login__forgot__password">Forgot password?</div>
                    <div className="login__new__account">Don't have account? <Link to="/account/register">Create account</Link> </div>
                </div>
            </div>
        </div>
     );
}
 
export default LoginCard;