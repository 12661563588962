import React, { Component, Fragment, useEffect, useState } from "react";
import Slider from "react-slick";
import ProductCard from "./Product";

import axios from "axios";
import "./slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";


 const ProductSlider =() => {

  const [ featuredItems, setFeaturedItems ] = useState([]);
  const [ smartWatch, setSmartWatch ] = useState([])
  const [ earbuds, setEarbuds ] = useState([])
  const [ neckband, setNeckband ] = useState([])
  const [ headphone, setHeadphone ] = useState([])
  const [ categorysmartWatch, setCategorysmartWatch ] = useState([])
  const [ categoryearbuds, setCategoryearbuds ] = useState([])
  const [ categoryneckband, setCategoryneckband ] = useState([])
  const [ categoryheadphone, setCategoryheadphone ] = useState([])

    useEffect(() => {
      const loadPost= async () => {
         //axios.get("https://shema-backend.vercel.app/api/items")
        axios.get("https://clientvoodlife.ursindia.com/api/Product/items")
              
            .then(res => {
               setSmartWatch(res.data.filter((item) => item.category === "Smart Watch"))
               setEarbuds(res.data.filter((item) => item.category === "Earbuds" ))
               setNeckband(res.data.filter((item) => item.category === "Neck Band"))
               setHeadphone(res.data.filter((item) => item.category === "Head Phone"))
              //  setFeaturedItems(res.data)
              })
            .catch(err => console.log(err))

        window.scrollTo(0, 0)
      }
        loadPost();
}, []);

useEffect(() => {
  const loadcategory= async () => {

      // Till the data is fetch using API 
      // the Loading page will show.
      //setLoading(true);

      // Await make wait until that 
      // promise settles and return its result
      const response = await axios.get(
      "https://clientvoodlife.ursindia.com/api/category/get_category")
      .then(response => {
      // After fetching data stored it in posts state.
          setCategorysmartWatch(response.data.filter((item) => item.name === "Smart Watch"))
          setCategoryearbuds(response.data.filter((item) => item.name === "Earbuds" ))
          setCategoryneckband(response.data.filter((item) => item.name === "Neck Band"))
          setCategoryheadphone(response.data.filter((item) => item.name === "Head Phone"))
      })
      .catch(error => {
        console.error(error);
      });
      // Closed the loading page
      //setLoading(false);
  }
     // Call the function
     loadcategory();
 }, []);


  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  };
    return (
      <div className="container" style={{paddingTop:50}}>
        <div style={{textAlign:"left",color:"gray",paddingBottom:5,paddingLeft:20}}><h2> Smart Watch</h2></div>
        <Slider {...settings}>
          {/* const [posts, setPosts] = useState([]); */}
          {smartWatch.map((item) => {
            return (
              <div key={item._id}>
                
                {/* <ProductCard imgSrc={item.name} /> */}
                <ProductCard imgSrc={item} />

                {/* <img src={slide.img} alt={`slide${index}`} /> */}
              </div>
            );
          })}
        </Slider>
        {/* <div className="img-fluid container" style={{width:"100%"}}>
          <img src={categorysmartWatch.map(item=>item.category_Banner)} style={{width:"inherit"}}></img>
        </div> */}


        <div style={{paddingTop:70}}>
        <div style={{textAlign:"left",color:"gray",paddingBottom:5,paddingLeft:20}}><h2> Earbuds</h2></div>
        <Slider {...settings}>
          {/* const [posts, setPosts] = useState([]); */}
          {earbuds.map((item) => {
            return (
              <div key={item._id}>
                
                {/* <ProductCard imgSrc={item.name} /> */}
                <ProductCard imgSrc={item} />

                {/* <img src={slide.img} alt={`slide${index}`} /> */}
              </div>
            );
          })}
        </Slider>
        {/* <div className="img-fluid container" style={{width:"100%"}}><img src={categoryearbuds.map(item=>item.category_Banner)} style={{width:"inherit"}}></img>
        </div> */}
        </div>


        <div style={{paddingTop:70}}>
        <div style={{textAlign:"left",color:"gray",paddingBottom:5,paddingLeft:20}}><h2> Neck band</h2></div>
        <Slider {...settings}>
          {/* const [posts, setPosts] = useState([]); */}
          {neckband.map((item) => {
            return (
              <div key={item._id}>
                
                {/* <ProductCard imgSrc={item.name} /> */}
                {/* <ProductCard imgSrc={item.productImage} /> */}
                <ProductCard imgSrc={item} />

                {/* <img src={slide.img} alt={`slide${index}`} /> */}
              </div>
            );
          })}
        </Slider>
        {/* <div className="img-fluid container" style={{width:"100%"}}>
        <img src={categoryneckband.map(item=>item.category_Banner)} style={{width:"inherit"}}></img>
        </div> */}
        </div>


        <div style={{paddingTop:70}}>
        <div style={{textAlign:"left",color:"gray",paddingBottom:5,paddingLeft:20}}><h2> Head Phone</h2></div>
        <Slider {...settings}>
          {/* const [posts, setPosts] = useState([]); */}
          {headphone.map((item) => {
            return (
              <div key={item._id}>
                
                {/* <ProductCard imgSrc={item.name} /> */}
                <ProductCard imgSrc={item} />

                {/* <img src={slide.img} alt={`slide${index}`} /> */}
              </div>
            );
          })}
        </Slider>
        {/* <div className="img-fluid container" style={{width:"100%"}}><img src={categoryheadphone.map(item=>item.category_Banner)} style={{width:"inherit"}}></img>
        </div> */}
        </div>

      </div>
    );
}

export default ProductSlider;





































































// import React, { Component } from "react";
// import Slider from "react-slick";
// import ProductCard from "./Product";

// import "./slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import "./style.css";

//  class ProductSlider extends Component {
//   constructor() {
//     super();
//     this.state = {
//       slides: [
//         {
//           img: "https://dummyimage.com/600x400/000/7CFC00"
//         },
//         {
//           img: "https://dummyimage.com/600x400/000/ccccc"
//         },
//         {
//           img: "https://dummyimage.com/600x400/000/dddddd"
//         },
//         {
//           img: "https://dummyimage.com/600x400/000/fff"
//         },
//         {
//           img: "https://dummyimage.com/600x400/000/B22222"
//         },
//         {
//           img: "https://dummyimage.com/600x400/000/7CFC00"
//         },
//         {
//           img: "https://dummyimage.com/600x400/000/ccccc"
//         },
//         {
//           img: "https://dummyimage.com/600x400/000/dddddd"
//         },
//         {
//           img: "https://dummyimage.com/600x400/000/B22222"
//         },
//         {
//           img: "https://dummyimage.com/600x400/000/7CFC00"
//         }
//       ]
//     };
//   }
//   render() {
//     var settings = {
//       dots: false,
//       infinite: false,
//       speed: 500,
//       slidesToShow: 4,
//       slidesToScroll: 2,
//       initialSlide: 1,

//       responsive: [
//         {
//           breakpoint: 1024,
//           settings: {
//             slidesToShow: 4,
//             slidesToScroll: 4,
//             infinite: true,
//             dots: true
//           }
//         },
//         {
//           breakpoint: 600,
//           settings: {
//             slidesToShow: 2,
//             slidesToScroll: 2,
//             initialSlide: 2
//           }
//         },
//         {
//           breakpoint: 480,
//           settings: {
//             slidesToShow: 2,
//             slidesToScroll: 2
//           }
//         }
//       ]
//     };

    
//     return (
//       <div>
//         <h2> Responsive Product Carousel</h2>
//         <Slider {...settings}>
//           {this.state.slides.map((slide, index) => {
//             return (
//               <div key={index}>
                
//                 <ProductCard imgSrc={slide.img} />
//                 {/* <img src={slide.img} alt={`slide${index}`} /> */}
//               </div>
//             );
//           })}
//         </Slider>
//       </div>
//     );
//   }
// }

// export default ProductSlider;



