import Carousel from 'react-bootstrap/Carousel';
import './ItemCarousel.css'
import CarousalItem from './CarousalItem';
import { useState } from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { BorderColor } from '@mui/icons-material';

const divStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: 'cover',
  autoPlay:true
  // width: '100%',
  // height:'550px'
}





const ProductCarousel = (props) => {
  const [imgvalue,setImgvalue]=useState([]);

  const get_image = (event) => {
    setImgvalue(event.target.currentSrc)
  };

  const slideImages=useState(props.item._Details_Images);
    return (
      <div className="product__carousel__container">
        <div className='row' flex>
            <div className='col-4'>
              <div style={{display:'grid',padding:2}}>
            {props.item._Details_Images.map((data) => (
                <button onClick={get_image} style={{widows:120,height:120,border:'none',padding:5}}>
                    <CarousalItem pro_Image={data.pro_Image}/>
                </button>
            ))} 
             </div>
              </div>
            <div className='col-8'>
              <div>
              <img src={imgvalue==""?props.item._Details_Images.length>0?props.item._Details_Images[0].pro_Image:imgvalue:imgvalue} alt='Image' className="carousel__image" ></img>
              </div>

              </div>
          </div>
        <div className="product__carousel">
          {/* <Carousel variant="dark" interval={4000}> */}
          
          
         
            {/* {props.item._Details_Images.map((data) => <CarousalItem pro_Image={data.pro_Image}/>)} */}
          
            {/* <Slide>
            {slideImages.map((slideImage, index)=> (
                <div key={index}>
                  <div style={{ ...divStyle,  }}>
                  <img
                      src={slideImage.pro_Image}
                      alt="Second slide"
                      />
                  </div>
                </div>
              ))} 
          </Slide> */}


            {/* {props.item._Details_Images} */}
                
            
            {/* <Carousel.Item>
            <div className="carousel__image__container">
                <img className="carousel__image" src={`https://shema-backend.vercel.app/public/${props.item.category}/${props.item.image[0].filename}`} alt="item"/>
            </div>
            </Carousel.Itelem>  
            <Carousel.Item>
            <div className="carousel__image__container">
                <img className="carousel__image" src={`https://shema-backend.vercel.app/public/${props.item.category}/${props.item.image[1].filename}`} alt="item"/>
              </div>
            </Carousel.Item>    */}
            {/* <Carousel.Item>
            <div className="carousel__image__container">
                <img className="carousel__image" src={`https://shema-backend.vercel.app/public/${props.item.category}/${props.item.image[2].filename}`} alt="item"/>
              </div>
            </Carousel.Item> */}
          {/* </Carousel> */}
        </div>
      </div>
     );
}
 
export default ProductCarousel;