import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import './ItemCarousel.css'
import { BorderColor } from "@mui/icons-material";

const CarousalItem =(props)=>{
    return(
            // <Carousel.Item>
                // <div className="carousel__image__container">
                <div>
                    <img style={{height:120,width:120}} src={props.pro_Image} alt="item" />
                    </div>
                // </div>
            //  </Carousel.Item>  
            );
}

export default CarousalItem;