
import React from 'react';
import axios from 'axios';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

// const spanStyle = {
//     padding: '20px',
//     background: '#efefef',
//     color: '#000000'
//   }
  
  const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    autoPlay:true
    // width: '100%',
    // height:'550px'
  }


 
  
  const slideImages = [
    {
      url: 'https://adminvoodlife.ursindia.com/EcartAdmin/productBanner/whatsapp_image_2023-10-27_at_13.27.31_6be67094.jpg',
      caption: 'Slide 1'
    },
    {
      url: 'https://adminvoodlife.ursindia.com/EcartAdmin/productBanner/whatsapp_image_2023-10-27_at_13.27.32_c1619694.jpg',
      caption: 'Slide 2'
    },
    {
      url: 'https://adminvoodlife.ursindia.com/EcartAdmin/productBanner/whatsapp_image_2023-10-27_at_13.27.32_bceeb27b.jpg',
      caption: 'Slide 3'
    },
    {
      url: 'https://adminvoodlife.ursindia.com/EcartAdmin/productBanner/whatsapp_image_2023-10-27_at_13.27.32_c6eec9f2.jpg',
      caption: 'Slide 2'
    },
  ];
  
  const Landing = () => {
    
  //   const [slideImages, setSlideImages] = useState([]);
  //   useEffect(() => {
  //     const loadPost= async () => {
  //         const response = await axios.get(
  //             "https://clientvoodlife.ursindia.com/api/Values/Get_Banner")
  //             .then(response => {
  //             setSlideImages(response.data);
  //             })
  //             .catch(error => {
  //           console.error(error);
  //         });
  //     }
  //     loadPost();
  // }, []);

      return (
        <div className="slide-container">
          <Slide>
           {slideImages.map((slideImage, index)=> (
              <div key={index}>
                <div style={{ ...divStyle,  }}>
                <img
                    className="w-100"
                    src={slideImage.url}
                    alt="Second slide"
                    />
                {/* 'backgroundImage': `url(${slideImage.url})` */}
                  {/* <span style={spanStyle}>{slideImage.caption}</span> */}
                </div>
              </div>
            ))} 
          </Slide>
        </div>
      )
  }


  export default Landing;