import  React,{useState,useEffect,lazy,createContext,useContext} from 'react';
import axios from 'axios';
// import { useContext } from "react";
import { FeatureCategoryContext } from "../../../Context/FeaturedCategoryContext";
import CategoryCard from "../../Card/FeaturedCard/CategoryCard";
import './FeaturedCategories.css'

const Categories = () => {
    // const featuredCategories = useContext(FeatureCategoryContext)

   const [posts, setPosts] = useState([]);
     
   useEffect(() => {
     const loadPost= async () => {
 
         // Till the data is fetch using API 
         // the Loading page will show.
         //setLoading(true);
 
         // Await make wait until that 
         // promise settles and return its result
         const response = await axios.get(
         "https://clientvoodlife.ursindia.com/api/category/get_category")
         .then(response => {
         // After fetching data stored it in posts state.
         setPosts(response.data);
         })
         .catch(error => {
           console.error(error);
         });
         // Closed the loading page
         //setLoading(false);
     }
 
     // Call the function
     loadPost();
 }, []);
    
    return ( 
        <div className="featured__categories__container">
            <div className="featured__categories">
                <div className="featured__categories__header">
                    <h1 className='featured__header__big'>Explore Popular Categories </h1>
                    {/* <div className="featured__categories__header__line"></div> */}
                </div>
                <div className="featured__categories__card__container">
                    {/* { featuredCategories.map((category) =>  <CategoryCard key={category.id} data={category}/>)} */}
                    { posts.map((post) =>  <CategoryCard key={post.id} data={post}/>)}
                </div>
            </div>
        </div>  
     );
}
 
export default Categories;