import './NavBrand.css'
import { Link } from 'react-router-dom';
import logo from '../../../asset/Logo/Voods_logo.png';

const NavBrand = () => {
    return ( 
        <div href="#home" className='navbrand__container'>
           <h1 className='navbrand'>
               <Link to="/" style={{color:'white'}}><img src={logo} style={{width:150}}></img></Link>
            </h1>
        </div>
     );
}
 
export default NavBrand;