import { Link,Route, Routes } from 'react-router-dom';
import './NavLinks.css'

const NavLinks = () => {
    return ( 
            <nav className="nav__bottom__container">
                <div className="bottom__container">
                    <ul className="nav">
                        <li className='nav-link'><Link to="/">Home</Link></li> 
                        {/* <li className='nav-link'><Link to="/shop">Shop</Link> </li> */}
                        <li className='nav-link'><Link to="/category/Smart-Watch">Smart watch</Link></li> 
                        <li className='nav-link'><Link to="/category/Head-Phone">Head Phone</Link></li> 
                        <li className='nav-link'><Link to="/category/Earbuds">Earbuds</Link></li>
                        <li className='nav-link'><Link to="/category/Neck-band">Neck band</Link></li>
                        {/* <li className='nav-link'><Link to="/category/kids">kids</Link></li> */}
                    </ul>
                </div>
                <Routes>
                    <Route to="./category" element={<category/>}></Route>
                </Routes>
            </nav>
            
     );
           
}
 
export default NavLinks;