import React from "react";

const LI=(props)=>{
    return(
<li style={{ listStyleType: 'disc'}}>
    {props.highlights}
</li>
)
}

export default LI;