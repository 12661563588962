import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import Landing from "../Components/Landing/Landing";
import FeaturedItems from "../Components/Featured/Items/FetauredItems";
import FeaturedCategories from "../Components/Featured/Categories/FeaturedCategories";
import { TabTitle } from "../utils/General";


const Home = () => {
    TabTitle("Voods");
    return ( 
        <Fragment>
            <Landing />
            <FeaturedCategories />
            {/* <FeaturedItems items={featuredItems}/> */}
            <FeaturedItems/>
        </Fragment>
    );
}
 
export default Home;