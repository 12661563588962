import React from "react";
import { Card, Button } from "react-bootstrap";
import { Link, useHistory } from 'react-router-dom';

const ProductCard = props => {
  return (
    <>
    <Link to={`/item/${props.imgSrc._id}`} items={props}> 
    {/* onClick={onClickItem} role="presentation" */}
    <div className="product-display" >
      <Card style={{ width: "inherit" }}>
        <Card.Img variant="top" src={props.imgSrc.productImage}/>
        <Card.Body>
          <Card.Title>{props.imgSrc.category}</Card.Title>
          <Card.Text>
          {props.imgSrc.name}   
          </Card.Text>
          <div>
          {!props.imgSrc.price===""?"":"Not Mentioned yet"}   
          </div>
          {/* <div className="product-actions">
            <Button variant="primary">Buy Now</Button>
            <Button variant="secondary">Add to cart</Button>
          </div> */}
        </Card.Body>
      </Card>
      </div>
      </Link>
    </>
  );
};

export default ProductCard;
